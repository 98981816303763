import { SelectRefundOption } from '../details.types';
import { DEFAULT_PREMIUM_CANCELLATION_FEE } from '../details.constants';

export const validateLeaseStartDate = (date: Date | null): string | undefined => {
  if (!date) {
    return 'Select a date';
  }
};

export const validateLeaseEndDate = (endDate: Date | null, startDate: Date): string | undefined => {
  if (!endDate) {
    return 'Select a date';
  }

  if (endDate.getTime() <= startDate.getTime()) {
    return 'Should be later than Lease Start';
  }
};

export const validateAddressUnit = (unit: string) => {
  if (!unit) {
    return "Can't be empty";
  }
};

export const validateMonthlyRent = (monthlyRentString: string) => {
  if (monthlyRentString.endsWith('.')) {
    return 'Invalid value';
  }

  if (isNaN(Number(monthlyRentString))) {
    return 'Should be a number';
  }

  if (!monthlyRentString) {
    return "Can't be empty";
  }
};

export const validateRentCoverage = (rentCoverageString: string) => {
  if (rentCoverageString.endsWith('.')) {
    return 'Invalid value';
  }

  if (Number(rentCoverageString) < 0) {
    return 'Should be a positive number';
  }

  if (!rentCoverageString) {
    return "Can't be empty";
  }
};

export const validateDepositsCoverage = (depositsCoverageString: string) => {
  if (depositsCoverageString.endsWith('.')) {
    return 'Invalid value';
  }

  if (Number(depositsCoverageString) < 0) {
    return 'Should be a positive number';
  }

  if (!depositsCoverageString) {
    return "Can't be empty";
  }
};

export const validateFreeRent = (freeRentString: string) => {
  if (freeRentString.endsWith('.')) {
    return 'Invalid value';
  }

  if (Number(freeRentString) < 0) {
    return 'Should be a positive number';
  }
};

export const validatePrepaidRent = (prepaidRentString: string) => {
  if (prepaidRentString.endsWith('.')) {
    return 'Invalid value';
  }

  if (Number(prepaidRentString) < 0) {
    return 'Should be a positive number';
  }
};

export const validateUserInput = (value: string | undefined, options: SelectRefundOption[]): number | null => {
  if (value === undefined) return null;

  const optionObject = options.find((opt) => opt.option === value);
  return Number(optionObject?.value ?? value);
};

export const normalizePremiumCancellationFee = (value: string) => {
  const number = Number(value);
  if (isNaN(number) || !isFinite(number)) return DEFAULT_PREMIUM_CANCELLATION_FEE;

  return number;
};

export const validatePMSIFields = (value: string) => {
  return isValidUUID(value) ? undefined : 'Value should be a valid UUID';
};

const isValidUUID = (uuid: string): boolean => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};
