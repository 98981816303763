import gql from 'graphql-tag';

const DealTypeDef = gql`
  type LeaseEntity {
    id: Int!
    propertyId: Int!
    property: PropertyEntity
    monthlyRent: Float
    leaseStartDate: String
    leaseEndDate: String
    addressUnit: String
    freeMonthsStartDate: String
    freeMonthsEndDate: String
    prepaidMonthsStartDate: String
    prepaidMonthsEndDate: String
    rentCoverage: Float
    depositsCoverage: Float
    freeRent: Float
    prepaidRent: Float
  }

  type DealEntity {
    id: Int!
    leaseId: Int!
    carrier: String
    lease: LeaseEntity
    policy: PolicyEntity
  }
`;

export default [DealTypeDef];
