import { ReactNode } from 'react';
import { format, parse, parseISO } from 'date-fns';
import {
  ApplicationUpdates,
  DetailsChanges,
  DetailsChangesKey,
  LeaseUpdates,
  ObjectWithStringKeys,
} from './details.types';
import { ApplicationSettings } from '@theguarantors/tg-modern-business';

const allowedSectionNames = Object.values(DetailsChangesKey);

export const formatStringDate = (dateString: string, inputFormat = 'yyyy-MM-dd', outputFormat = 'yyyy/MM/dd') =>
  format(parse(dateString, inputFormat, new Date()), outputFormat);

export const formatIsoStringDate = (dateString: string, outputFormat = 'yyyy/MM/dd') =>
  format(parseISO(dateString), outputFormat);

export const formatIsoStringDateWithouTimezone = (dateString: string, outputFormat = 'yyyy/MM/dd') =>
  format(parseISO(dateString.slice(0, -1)), outputFormat);

export const getAmountString = (amount: number) =>
  isNaN(amount) ? String(amount) : `$${new Intl.NumberFormat().format(Number(amount.toFixed(2)))}`;
export const getInfoContainerDataRow = (label: ReactNode, value: ReactNode) => ({
  label,
  value,
});

export const getTruthyValuesCount = (obj: Record<string, unknown> = {}) => {
  return Object.values(obj ?? {}).filter((prop) => prop).length;
};

export const getErrorsCount = (changes: DetailsChanges[DetailsChangesKey]): number =>
  Object.values(changes).reduce((errorsCount: number, change) => (change?.error ? errorsCount + 1 : errorsCount), 0);

export const formatStringToFixedNumber = (numberString: string | undefined, fixedNumber = 2): number | undefined =>
  numberString ? Number(Number(numberString).toFixed(fixedNumber)) : undefined;

export const getLeaseChangedValues = (detailsChanges?: DetailsChanges[DetailsChangesKey.LEASE]): LeaseUpdates => ({
  leaseStartDate: detailsChanges?.leaseStartDate?.value
    ? format(detailsChanges?.leaseStartDate?.value, 'yyyy-MM-dd')
    : undefined,
  leaseEndDate: detailsChanges?.leaseEndDate?.value
    ? format(detailsChanges?.leaseEndDate?.value, 'yyyy-MM-dd')
    : undefined,
  monthlyRent: formatStringToFixedNumber(detailsChanges?.monthlyRent?.value),
  addressUnit: detailsChanges?.addressUnit?.value,
  rentCoverage: formatStringToFixedNumber(detailsChanges?.rentCoverage?.value),
  depositsCoverage: formatStringToFixedNumber(detailsChanges?.depositsCoverage?.value),
  freeRent: formatStringToFixedNumber(detailsChanges?.freeRent?.value),
  prepaidRent: formatStringToFixedNumber(detailsChanges?.prepaidRent?.value),
});

export const getApplicationSettingsChangedValues = (
  detailsChanges?: DetailsChanges[DetailsChangesKey.APPLICATION_SETTINGS],
): ApplicationSettings['data'] => {
  const applicationSettings: ObjectWithStringKeys = {};

  if (detailsChanges) {
    for (const key in detailsChanges) {
      if (detailsChanges[key]) {
        applicationSettings[key] = detailsChanges[key].value;
      }
    }
  }

  return applicationSettings;
};

export const getApplicationChangedValues = (
  detailsChanges?: DetailsChanges[DetailsChangesKey.APPLICATION],
): ApplicationUpdates => {
  const application: ObjectWithStringKeys = {};

  if (detailsChanges) {
    application.lgCoverageOverride = formatStringToFixedNumber(detailsChanges.lgCoverageOverride?.value);
    application.sdrCoverageOverride = formatStringToFixedNumber(detailsChanges.sdrCoverageOverride?.value);
    application.screeningStatus = detailsChanges.screeningStatus?.value;
    application.pmsiCurrentResidentMatchId = detailsChanges.pmsiCurrentResidentMatchId?.value;
    application.pmsiProspectMatchId = detailsChanges.pmsiProspectMatchId?.value;
  }

  return application;
};

export const getChangedSections = (changes: Partial<DetailsChanges>) => {
  const sectionNames = Object.keys(changes) as Array<keyof DetailsChanges>;
  const changedSectionNames = sectionNames.filter(
    (sectionName) => changes[sectionName] && allowedSectionNames.includes(sectionName),
  );
  return changedSectionNames.reduce<Array<DetailsChanges[DetailsChangesKey]>>((result, sectionName) => {
    const section = changes[sectionName];
    if (section) {
      result.push(section);
    }

    return result;
  }, []);
};

export const convertToCents = (value: number | string): number => {
  const valueToNumber = Number(value);
  if (isNaN(valueToNumber)) return 0;

  return parseFloat((valueToNumber * 100).toFixed(0));
};
