import gql from 'graphql-tag';

const ApplicationSettingsTypeDef = gql`
  extend type Mutation {
    dealDetailsUpdate(dealId: Int!, detailsToUpdate: DealDetailsUpdateInput!): DealDetailsUpdateResponse
  }

  input DealDetailsUpdateInput {
    application: UpdateApplicationInput
    applicationSettings: ApplicationSettingsInput
    lease: UpdateLeaseInput
  }

  input UpdateApplicationInput {
    lgCoverageOverride: Float
    sdrCoverageOverride: Float
    screeningStatus: String
    pmsiCurrentResidentMatchId: String
    pmsiProspectMatchId: String
  }

  type DealDetailsUpdateResponse {
    applicationSettings: ApplicationSettings
    lease: LeaseEntity
    application: ApplicationEntity
  }
`;

export default [ApplicationSettingsTypeDef];
