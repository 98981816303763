import { Box, InfoContainer } from '@theguarantors/ui-kit-v3';
import { FeatureFlag, useFeatureFlag } from '../../../../hooks/use-feature-flag';
import React, { useMemo } from 'react';
import { ReadableAnswers } from '../../details.constants';
import { MonthlyPayment, QuoteInfoProps } from '../../details.types';
import { getAmountString, getInfoContainerDataRow } from '../../details.utils';
import { ParseQuoteDataFromApplication } from '../../utils/ParseQuoteDataFromApplication';
import { datadogLogs } from '@datadog/browser-logs';

export function getAmountOrNullableAnswer(value: number | undefined): string {
  return value || value === 0 ? getAmountString(value) : ReadableAnswers.NULL;
}

export const QuoteInfo: React.FC<QuoteInfoProps & MonthlyPayment> = ({ application, isMonthlyPayment, ...props }) => {
  const [displayNewDetailsFeatureFlag] = useFeatureFlag(FeatureFlag.DISPLAY_NEW_DETAILS);
  const quoteData = new ParseQuoteDataFromApplication(application).getQuoteData();

  datadogLogs.logger.info('Displaying fields in quoteData object', {
    quoteData: { ...quoteData },
    component: 'QuoteInfo',
  });

  const allPremiums = useMemo(() => {
    if (isMonthlyPayment) {
      return {
        lgPremiumAmount: quoteData?.sdrPremiumAmountWithoutUpfrontDiscount
          ? quoteData?.lgPremiumAmountWithoutUpfrontDiscount
          : quoteData?.lgPremiumAmount
          ? quoteData?.premiumAmountWithoutUpfrontDiscount
          : quoteData?.lgPremiumAmount,
        sdrPremiumAmount: quoteData?.lgPremiumAmountWithoutUpfrontDiscount
          ? quoteData?.sdrPremiumAmountWithoutUpfrontDiscount
          : quoteData?.sdrPremiumAmount
          ? quoteData?.premiumAmountWithoutUpfrontDiscount
          : quoteData?.sdrPremiumAmount,
        premiumAmount: quoteData?.premiumAmountWithoutUpfrontDiscount,
      };
    }

    return {
      lgPremiumAmount: quoteData?.lgPremiumAmount,
      sdrPremiumAmount: quoteData?.sdrPremiumAmount,
      premiumAmount: quoteData?.premiumAmount,
    };
  }, [isMonthlyPayment, quoteData]);
  const quoteDetails = useMemo(() => {
    return [
      getInfoContainerDataRow(
        'Filing used',
        <Box data-testid="auto_quote_rate_filing_data">{quoteData?.rateFilingData}</Box>,
      ),
      getInfoContainerDataRow('Carrier', <Box data-testid="auto_quote_carrier">{quoteData?.carrier}</Box>),
      getInfoContainerDataRow(
        'Premium rate',
        <Box data-testid="auto_quote_premium_rate">{quoteData?.premiumRate}</Box>,
      ),
      getInfoContainerDataRow(
        'Risk category',
        <Box data-testid="auto_quote_risk_category">{quoteData?.riskCategory}</Box>,
      ),
      displayNewDetailsFeatureFlag === 'on'
        ? getInfoContainerDataRow(
            'LG Premium',
            <Box data-testid="auto_quote_premium">{getAmountOrNullableAnswer(allPremiums.lgPremiumAmount)}</Box>,
          )
        : undefined,
      displayNewDetailsFeatureFlag === 'on'
        ? getInfoContainerDataRow(
            'SDR Premium',
            <Box data-testid="auto_quote_add_risk_premium">
              {getAmountOrNullableAnswer(allPremiums.sdrPremiumAmount)}
            </Box>,
          )
        : undefined,
      displayNewDetailsFeatureFlag === 'on'
        ? getInfoContainerDataRow(
            'Total Premium',
            <Box data-testid="auto_quote_premium_amount">{getAmountOrNullableAnswer(allPremiums.premiumAmount)}</Box>,
          )
        : undefined,
      getInfoContainerDataRow(
        'LG Coverage (Mo.)',
        <Box data-testid="auto_quote_coverage_months">{quoteData?.coverageMonths}</Box>,
      ),
      getInfoContainerDataRow(
        'SDR amount',
        <Box data-testid="auto_quote_amount_sdr">{getAmountOrNullableAnswer(quoteData?.amountSDR)}</Box>,
      ),
      getInfoContainerDataRow(
        'Penal sum',
        <Box data-testid="auto_quote_penal_sum">{getAmountOrNullableAnswer(quoteData?.penalSum)}</Box>,
      ),
      displayNewDetailsFeatureFlag === 'on'
        ? getInfoContainerDataRow(
            'Rent Coverage',
            <Box data-testid="auto_quote_rent_coverage">{getAmountOrNullableAnswer(quoteData?.rentCoverage)}</Box>,
          )
        : undefined,
      displayNewDetailsFeatureFlag === 'on'
        ? getInfoContainerDataRow(
            'Deposits Coverage',
            <Box data-testid="auto_quote_deposits_coverage">
              {getAmountOrNullableAnswer(quoteData?.depositsCoverage)}
            </Box>,
          )
        : undefined,
    ];
  }, [quoteData, displayNewDetailsFeatureFlag, allPremiums]);

  const data = quoteDetails.filter((detail) => Boolean(detail));

  return (
    <InfoContainer
      color="neutral.main"
      title="Quote Details"
      data={data}
      dataBlockColProps={{ style: { textTransform: 'capitalize' } }}
      extra={{
        icon: 'lock',
        tooltipTitle: 'This information can`t be edited',
        tooltipId: 'dLargeButton',
      }}
      {...props}
    />
  );
};
